@import url("./fonts/stylesheet.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

#tsparticles {
  position: fixed !important;
  z-index: -5;
  height: 100vh;
}
